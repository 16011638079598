<app-search #search></app-search>
<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top" [style.color]="color">
    <div id="background" class="background"></div>
    <!-- Image Logo -->


    <div *ngIf="noH1;then H1Header else noH1Header"></div>
    <ng-template #H1Header>
          <h1 style="display:contents">
              <a class="navbar-brand logo-image" routerLink="/{{ currentLang}}"> <img [src]="icon" alt="happygaytv icon how to be gay and happy"></a>
          </h1>
    </ng-template>
    <ng-template #noH1Header>
            <a class="navbar-brand logo-image" routerLink="/{{ currentLang}}"> <img [src]="icon" alt="happygaytv icon"></a>
    </ng-template>
    <!-- Mobile Menu Toggle Button -->
    <button *ngIf="hideButton" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-awesome fas fa-bars"></span>
        <span class="navbar-toggler-awesome fas fa-times"></span>
    </button>
    <!-- end of mobile menu toggle button -->

    <div *ngIf="hideButton" class="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul id="nav" class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" style="color: #FF0090" [routerLink]="['/hotline' | localize]">{{
                    'data.navbar.hotline' | translate }}</a>
            </li>
            <li class="nav-item">
                 <a class="nav-link" [routerLink]="['/club' | localize]">Happy<span style='color: #ff0000'>Gay</span>TV
                    <span style='color: #ff0000'>Club</span></a>
            </li>
            <li class="nav-item">
             <a class="nav-link" [routerLink]="['/plus' | localize]">Happy<span style='color: #FF5F00'>Gay</span>TV
                     <span style='color: #FF5F00'>+</span></a>
	             </li>
             <li class="nav-item dropdown btn-group">
                 <a class="nav-link btn blog-button" [routerLink]="['/blog' | localize]">{{ 'data.navbar.blog' | translate }}</a>
                 <span [style.display]="srcWidth < 992 ? 'inline' : 'none'" class="nav-link dropdown-toggle page-scroll" id="navbarDropdown" role="button" aria-haspopup="true"
                     aria-expanded="false"></span>
                 <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: max-content;">
                   <a *ngFor="let subCategory of subCategories" [routerLink]="['/blog' | localize , subCategory.slug]" class="nav-link">{{ subCategory.name }}</a>
                 </div>
             </li>
             <li class="nav-item">
              <span class="nav-link" (click)="goToIsFirstTime()" *ngIf="isFirstTime">{{ 'data.navbar.firstTimeStart' |
                translate }}<sup>{{ 'data.navbar.firstTimeOrdinal' | translate }}</sup> {{ 'data.navbar.firstTimeEnd' | translate }}</span>
            </li>
            <li class="nav-item">
               <span class="fa-stack">
                 <span class="nav-link" (click)="startSearch()">
	                   <i style="color: white;" class="fas fa-search fa-stack-1x"></i>
                 </span>
               </span>
             </li>
            <span class="nav-item">
                <span class="fa-stack">
                    <a href="https://www.facebook.com/happygaytv/" target="_blank">
                        <i style="color: white;" class="fab fa-facebook-f fa-stack-1x"></i>
                    </a>
                </span>
                <span class="fa-stack">
                    <a href="https://www.instagram.com/happygaytv/" target="_blank">
                        <i style="color: white;" class="fab fa-instagram fa-stack-1x"></i>
                    </a>
                </span>
                <span class="flex-elem" (click)='tip()'>
                  <img class="mug" src="/assets/img/mug-heart.svg" alt="happygaytv icon">
              </span>
            </span>
        </ul>
        <ul id="end-bar" class="navbar-nav ml-auto">
            <li *ngIf="isLoggedIn" class="nav-item dropdown mr-5">
                <span class="nav-link dropdown-toggle page-scroll" id="navbarDropdown" role="button" aria-haspopup="true"
                    aria-expanded="false"><i class="fas fa-users-cog"></i></span>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: max-content;">
                    <span class="nav-link" (click)='openDialog("login")' *ngIf="!isLoggedIn">{{ 'data.navbar.registration'
                        | translate }}</span>
                    <span class="dropdown-item nav-link" (click)='logout()' *ngIf="isLoggedIn">{{ 'data.navbar.logout' |
                        translate }}</span>
                </div>
            </li>
            <li class="nav-item lang">
                <span (click)="changeLang()">{{ (currentLang === 'fr' ? 'en' : 'fr') | titlecase }}</span>
            </li>
        </ul>
    </div>
</nav>
<!-- end of navbar -->
<!-- end of navigation -->
