export class Video {
  id: string;
  title: string;
  browserTitle: string;
  metaDescription: string;
  slug: string;
  vimeoId: string;
  description: string;
  subCategoryId: number;
  categoryId: number;
  trailerId: string;
  publishDate: Date;
  thumbnail: string;
  billboard: string;
  cover: string;
  coverAlt: string;
  club: boolean;
  plus: boolean;
  clubLevel: number;
  plusLevel: number;
}

export interface SubCategory {
  id: number;
  name: string;
  slug: string;
  plus: boolean;
  club: boolean;
}

export interface Category {
  id: number;
  name: string;
  slug: string;
  plus: boolean;
  club: boolean;
}

export class Seo {
  id: string;
  browserTitle: string;
  metaTitle: string;
  metaDescription: string;
  targetPage: string;
}

export class Playlist {
  id: string;
  type: PlaylistType;
}

type Rename<T, K extends keyof T, N extends string> = Pick<T, Exclude<keyof T, K>> & { [P in N]: T[K] };

type renamedPlaylist = Rename<Playlist, 'id', 'playlistId'>;

export type PlaylistVideo = renamedPlaylist & Video;

export class UserInput {
  nickname: string;
  password: string;
  email: string;
}

export enum Tag {
  HOTLINE = 'hotline',
  CLUB = 'club',
  PLUS = 'plus',
  HOTLINE_SEND = 'hotline_send'
}

export enum PlaylistType {
  GLOBAL = 'global',
  CLUB = 'club',
  PLUS = 'plus',
  BA = 'ba',
  HOTLINE = 'hotline',
  BACLUB = 'ba club',
  BAPLUS = 'ba plus',
  HEADLINE = 'headline',
  BILLBOARD = 'billboard',
  HEADLINECLUB = 'headline club',
  BILLBOARDCLUB = 'billboard club',
  TEASECLUB = 'tease club',
  SHOWCASE = 'showcase'
}
