import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDialogComponent } from 'app/auth/components/login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from 'app/auth/components/registration-dialog/registration-dialog.component';
import { LostPasswordDialogComponent } from 'app/auth/components/lost-password-dialog/lost-password-dialog.component';
import { CardDialogComponent } from 'app/auth/components/card-dialog/card-dialog.component';
import { CookieDialogComponent } from 'app/components/cookie-dialog/cookie-dialog.component';
import { TranslatesService } from '@shared/translates';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@shared/services/auth.service';
import { Tag } from '../../shared/types';
import { StateService } from '@shared/services/state.service';


@Injectable()
export class ExtFormService {

  constructor(
    private ngZone: NgZone,
    public router: Router,
    private translatesService: TranslatesService,
    private modalService: NgbModal,
    private stateService: StateService,
    private authService: AuthService,
  ) {

  }

  connect() {
    this.ngZone.run(async () => {
      const res = await this.startModal('login');
      if (res === 'success') {
        this.router.navigate(['/']);
      } else if (res === 'registration') {
        let resReg = res;
        while (resReg !== 'close') {
          resReg = await this.startModal(resReg);
          if (resReg === 'success') {
            this.router.navigate(['/']);
          } else if (resReg === 0) {
            return;
          }
        }
      } else if (res === 'lost-password') {
        const resLost = await this.startModal('lost-password');
      }
    });
  }

  clubConnect() {
    this.ngZone.run(async () => {
      if (this.authService.isAuthenticated() && this.authService.getUserType() === 'club') {
       this.router.navigate(['/' + this.translatesService.getCurrentLang() + '/classvip']);
        return;
      }
      const res = await this.startModal('login', false, true);
      if (res === 'success') {
        this.router.navigate(['/' + this.translatesService.getCurrentLang() + '/club']);
      } else if (res === 'registration') {
        let resReg = res;
        while (resReg !== 'close') {
          resReg = await this.startModal(resReg, false, true);
          if (resReg === 'success') {
            this.router.navigate(['/' + this.translatesService.getCurrentLang() + '/club']);
          } else if (resReg === 0) {
            return;
          }
        }
      } else if (res === 'lost-password') {
        const resLost = await this.startModal('lost-password');
      }
    });
  }

  extraCookie() {
    this.ngZone.run(async () => {
      const modal = this.modalService.open(CookieDialogComponent, {
        windowClass: 'cookie modal',
        scrollable: true,
      });
      const res = await modal.result;
    });
  }

  removeCard() {
    this.ngZone.run(async () => {
      if (!this.authService.isAuthenticated()) {
        const res = await this.startModal('login', true);
        if (res === 'success') {
          const modal = this.modalService.open(CardDialogComponent, {
            windowClass: 'card modal',
            scrollable: true,
          });
          await modal.result;
        } else if (res === 'lost-password') {
          const resLost = await this.startModal('lost-password');
        }
      } else {
        try {
          const modal = this.modalService.open(CardDialogComponent, {
            windowClass: 'card modal',
            scrollable: true,
          });
          await modal.result;
        } catch (e) {
        }
      }
    });
  }

  async startModal(modalName: string, noRegistration?: boolean, clubConnect?: boolean) {
    try {
      if (modalName === 'login') {
        const modal = this.modalService.open(LoginDialogComponent, {
          windowClass: 'login modal',
          scrollable: true,
        });
        modal.componentInstance.noRegistration = noRegistration;
        modal.componentInstance.clubConnect = clubConnect;
        return await modal.result;
      } else if (modalName === 'registration') {
        const modal = this.modalService.open(RegistrationDialogComponent, {
          windowClass: 'registration modal',
          scrollable: true,
        });
        if (clubConnect) {
          modal.componentInstance.origin = Tag.CLUB;
        }
        return await modal.result;
      } else if (modalName === 'lost-password') {
        const modal = this.modalService.open(LostPasswordDialogComponent, {
          windowClass: 'lost-password modal',
          scrollable: true,
        });
        return await modal.result;
      }
      return '';
    } catch (e) {
      return 0;
      // console.log(e);
    }
  }
}
