<!-- Footer -->
<div class="footer">
    <div class="" style="max-width: 100%;">
        <div class="row">
            <div class="col-md col-logo">
                <div class="footer-col logo">
                    <img class="logo" src="/assets/img/hgtv-logo-blue.png" alt="footer logo">
                </div>
            </div>
            <div class="col-md rights subLogo">
              <p style="margin: 0;padding: 0;font-size: x-small !important;">
                  {{ 'data.footer.copyright.1' | translate }}{{ currentYear}}</p>
              <p style="margin: 0;padding: 0;font-size: x-small !important;">
                  {{ 'data.footer.copyright.2' | translate }}</p>
              <p style="margin: 0;padding: 0;font-size: x-small !important;">
                  {{ 'data.footer.copyright.3' | translate }}</p>
              <span style="margin: 0;padding: 0;font-size: x-small !important;cursor: pointer;color: white;" (click)="contactDialog()">
                  {{ 'data.footer.copyright.4' | translate }}</span>
            </div>
            <div class="col-md subLogo">
                <div class="footer-col middle">
                    <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/confidentiality' | localize]" >{{ 'data.footer.condition.1' | translate }}</a>
                        </li>
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/sale' | localize]"
                              >{{ 'data.footer.condition.2' | translate }}</a>,
                        </li>
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/use' | localize]"
                                >{{ 'data.footer.condition.3' | translate }}</a>,
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md subLogo">
                <div class="footer-col middle">
                    <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/legal' | localize]"> {{ 'data.footer.condition.4' | translate }}</a>
                        </li>
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/cookies' | localize]">{{ 'data.footer.condition.5' | translate }}</a>,
                        </li>
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/data' | localize]">{{ 'data.footer.condition.6' | translate }}</a>,
                        </li>
                        <li class="media">
                            <a class="white" [routerLink]="['/pages/faq' | localize]">{{ 'data.footer.condition.7' | translate }}</a>,
                        </li>
                    </ul>
                </div>
            </div>
            <!-- end of col -->
            <div class="col-md subLogo">
                <div class="footer-col last">
                    <h2 class="follow">{{ 'data.footer.media' | translate }}</h2>
                      <fa-stack class="facebookStack">
                        <a href="https://www.facebook.com/happygaytv/" target="_blank">
                          <fa-icon [icon]="faCircle" stackItemSize="1x" class="circle"></fa-icon>
                          <fa-icon [icon]="faFacebookF" class="icon" stackItemSize="1x"></fa-icon>
                        </a>
                      </fa-stack>
                      <fa-stack>
                        <a href="https://twitter.com/HappyGayTV" target="_blank">
                          <fa-icon [icon]="faCircle" stackItemSize="1x" class="circle"></fa-icon>
                          <fa-icon [icon]="faTwitter" class="icon" stackItemSize="1x" ></fa-icon>
                        </a>
                      </fa-stack>
                      <fa-stack>
                        <a href="https://www.instagram.com/happygaytv/" target="_blank">
                          <fa-icon [icon]="faCircle" stackItemSize="1x" class="circle"></fa-icon>
                          <fa-icon [icon]="faInstagram" class="icon" stackItemSize="1x" ></fa-icon>
                        </a>
                      </fa-stack>
                   </div>
            </div>
            <!-- end of col -->
        </div>
        <!-- end of row -->
    </div>
    <!-- end of footer -->
</div>
<!-- end of footer -->
