import { Component, OnInit, Inject, NgZone, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { TranslatesService } from '@shared/translates';
import { Subscription } from 'rxjs';
import { StateService } from '@shared/services/state.service';
import { InternalCookieService } from '@shared/services/internalCookie.service';
import { UniversalStorage } from '@shared/storage/universal.storage';
import { isPlatformServer } from '@angular/common';
import { TargetPageService } from '@shared/services/targetPage.service';
import { SEOService } from '@shared/services/seo.service';
import { ExtFormService } from '@shared/services/extForm.service';
import { Title } from '@angular/platform-browser';
import { Seo } from '@shared/types';
import { environment } from 'environments/environment';

import { PixelService } from 'ngx-pixel';
declare var Convertful: any;
declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  statusChangeSubscription: Subscription;
  popupCloseSubscription: Subscription;
  loading = false;
  timeout;
  seo: Seo;
  cookieInit = false;
  isUploading = false;
  @ViewChild('progress') progress: ElementRef;

  constructor(
    public router: Router,
    private ccService: NgcCookieConsentService,
    private translatesService: TranslatesService,
    private ngZone: NgZone,
    private stateService: StateService,
    private internalCookie: InternalCookieService,
    private targetPageService: TargetPageService,
    private seoService: SEOService,
    private titleService: Title,
    private extFormService: ExtFormService,
    @Inject(UniversalStorage) private _appStorage: Storage,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private pixel: PixelService
  ) {
    // this.meta.setTag('og:title', 'happygaytv');
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
    if (!isPlatformServer(this._platformId)) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      window.addEventListener('resize', () => {
        // We execute the same script as before
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
    window['angularComponentRef'] = {component: this, zone: ngZone};
    this.stateService.loading.subscribe((loading) => {
      if (this.timeout && !loading) {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.loading = loading;
        return;
      }
      this.timeout = setTimeout(() => {
        this.loading = loading;
        this.timeout = null;
      }, 100);
    });

    this.stateService.upload.subscribe(async (value) => {
      if (value > -1) {
        this.isUploading = true;
      } else {
        this.isUploading = false;
        return;
      }

      if (this.progress) {
        if (value > 1) {
          value = 1;
        }
        const rounded = String(Math.round(value * 100));
        this.progress.nativeElement.style = 'width:' + rounded + '%;';
        this.progress.nativeElement.innerHTML = rounded + '%';
        this.progress.nativeElement.ariaValueNow = rounded;
      }
    });

    this.getBaseSeo();

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.updateSeo();
        if (this.cookieInit && event.url === '/404') {
          this.ccService.close(false);
        } else if (this._appStorage.getItem('cookieconsent_mark') === 'allow') {
          await new Promise(f => setTimeout(f, 500));
          if (Convertful) {
            Convertful.reinit();
          }
          if (gtag) {
            gtag('event', 'page_view', {'page_location': event.url});
            this.pixel.track('ViewContent', { content_name: event.url });
          }
        } else if (this.cookieInit && !this.ccService.hasAnswered()) {
          this.ccService.open();
        } else if (gtag) {
          gtag('event', 'page_view', {'page_location': event.url});
        }
      }
    });
  }

  private async getBaseSeo() {
    this.seo = await this.targetPageService.getByTargetPage('home');
    this.updateSeo();
  }

  private updateSeo() {
    this.titleService.setTitle(this.seo.metaTitle);

    if (isPlatformServer(this._platformId)) {
      this.seoService.updateOgUrl('https://' + environment.domain + '/' + this.router.url);
    } else {
      this.seoService.updateOgUrl(document.location.href);
    }

    this.seoService.updateDescription(this.seo.metaDescription);
    this.seoService.updateTitle(this.seo.metaTitle);

    const lang = this.translatesService.getCurrentLang() === 'fr' ? 'fr' : 'gb';
    const url = 'https://' + environment.domain + '/assets/img/logo-happygaytv-' + lang + '.png';
    if (isPlatformServer(this._platformId)) {
      this.seoService.updateOgImage('https://' + environment.domain + '/assets/img/logo-happygaytv-' + lang + '-banner.png');
    } else {
      this.seoService.updateOgImage(document.location.origin + '/assets/img/logo-happygaytv-' + lang + '-banner.png');
    }
  }

  connect() {
    this.extFormService.connect();
  }

  clubConnect() {
    this.extFormService.clubConnect();
  }

  extraCookie() {
    this.extFormService.extraCookie();
  }

  removeCard() {
    this.extFormService.removeCard();
  }

  ngOnInit() {
    this.internalCookie.addAnalytics();
    this.translatesService
      .getText(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(async data => {

          if (this.router.url !== '/404' && !isPlatformServer(this._platformId)) {
            this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
            // Override default messages with the translated ones
            this.ccService.getConfig().content.header = data['cookie.header'];
            this.ccService.getConfig().content.message = data['cookie.message'];
            this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
            this.ccService.getConfig().content.allow = data['cookie.allow'];
            this.ccService.getConfig().content.deny = data['cookie.deny'];
            this.ccService.getConfig().content.link = data['cookie.link'];
            this.ccService.getConfig().content.policy = data['cookie.policy'];
            this.ccService.getConfig().content.href = this.translatesService.getCurrentLang() + '/pages/cookies';

            this.ccService.destroy(); // remove previous cookie bar (with default messages)
            this.ccService.init(this.ccService.getConfig()); // update config with translated messages
            this.cookieInit = true;

            this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
              (event: NgcStatusChangeEvent) => {
                if (event.status === 'allow') {
                  this._appStorage.setItem('cookieconsent_mark', 'allow');
                  this.internalCookie.addConvertful();
                  this.internalCookie.allowGoogleCookies(this.translatesService.getCurrentLang());
                  this.pixel.initialize();
                  this.ccService.close(false);
                } else {
                  this._appStorage.setItem('cookieconsent_mark', 'deny');
                  this._appStorage.removeItem('conv_session');
                  this._appStorage.removeItem('conv_person');
                  this._appStorage.removeItem('conv_lastEvents');
                  this._appStorage.removeItem('g_id');
                  this._appStorage.removeItem('_gid');
                  this._appStorage.removeItem('_ga');
                  this._appStorage.removeItem('_gat');
                  this._appStorage.removeItem('conv_person');
                  this._appStorage.removeItem('conv_session');
                  this.pixel.remove();
                  window.location.reload();
                }
              });

              if (this.ccService.hasAnswered()) {
                if (this._appStorage.getItem('cookieconsent_mark') === 'allow') {
                  this.internalCookie.addConvertful();
                  this.internalCookie.allowGoogleCookies(this.translatesService.getCurrentLang());
                  this.pixel.initialize();
                }
                this.ccService.close(false);
                this.ccService.destroy();
                this.cookieInit = false;
              }
        }
      });
  }
}
